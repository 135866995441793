import React, {ReactNode, useEffect} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {MemberDetailsState} from '../../../types/app.types';
import {MemberDetailsSummary} from './MemberDetailsSummary/MemberDetailsSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {MemberAddressNotValidError} from './MemberAddressNotValidError/MemberAddressNotValidError';
import {useMemberDetailsData} from './WithMemberDetailsData';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  edit = 'MemberDetails.edit',
  new = 'MemberDetails.new',
  changeButton = 'MemberDetails.changeButton',
}

export const MemberDetails = ({Form, dropdownLabel}: {Form: () => ReactNode; dropdownLabel: string}) => {
  const {areMemberDetailsValid, selectedAddressesServiceId, memberDetailsState, setMemberDetailsState} =
    useMemberDetailsData();

  useEffect(() => {
    /* istanbul ignore if */
    if (memberDetailsState === MemberDetailsState.COLLAPSED && !areMemberDetailsValid) {
      setMemberDetailsState(MemberDetailsState.OPEN);
    }
  }, [areMemberDetailsValid, setMemberDetailsState, memberDetailsState]);

  return (
    <div>
      <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
        {memberDetailsState !== MemberDetailsState.COLLAPSED && <AddressDropdown dropdownLabel={dropdownLabel} />}
        {selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && memberDetailsState !== MemberDetailsState.EDIT && (
          <>
            <MemberDetailsSummary />
            {!areMemberDetailsValid && <MemberAddressNotValidError />}
          </>
        )}
        {memberDetailsState === MemberDetailsState.EDIT && <div data-hook={MemberDetailsDataHook.edit}>{Form()}</div>}
        {selectedAddressesServiceId === ADD_NEW_ADDRESS_ID && (
          <div data-hook={MemberDetailsDataHook.new}>NEW (TEMPORARY)</div>
        )}
      </div>
    </div>
  );
};
