import {FormValues} from '@wix/form-viewer';
import {VatIDValue} from '@wix/form-fields';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {CommonVatId, CommonVatType} from '@wix/ambassador-ecom-v1-checkout/types';
import {getCommonVatId} from '../../../../domain/utils/vat.util';

export const getVatFormInitialState = (contact?: ContactModel) => ({
  vat_id: {
    id: contact?.vatId?.id ?? '',
    type: contact?.vatId?.type ?? CommonVatType.UNSPECIFIED,
  },
});

export const getVatFromVatFormValues = (vatFormValues: FormValues): CommonVatId | undefined => {
  return getCommonVatId(vatFormValues.vat_id as VatIDValue);
};
