import {CashierMandatoryFieldsOverrides} from '../../../types/app.types';
import {COUNTRIES_WITH_VAT, FORMS_TEMPLATE_IDS} from '../constants';
import {getContactFormInitialState, getContactFormOverrides} from './ContactForm/contactForm.utils';
import {getAddressFormInitialState, getAddressFormOverrides} from './AddressForm/addressForm.utils';
import {getVatFormInitialState} from './VatIdForm/VatForm.utils';
import {validate} from '@wix/form-viewer';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions';
import {MemberDetailsValidator} from '../MemberDetails/WithMemberDetailsData';

// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyNestedForms} = require('@wix/form-viewer/dist/esm/services/apply-nested-forms');
// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-var-requires
const {applyFormOverrides} = require('@wix/form-viewer/dist/esm/services/form-overrides');

export const isBillingInfoValid: MemberDetailsValidator<CashierMandatoryFieldsOverrides> = async ({
  formsById,
  addressAndContact,
  checkoutSettings,
  context: cashierMandatoryFields,
}) => {
  const contactFormSchema: ReturnType<typeof applyRuleOverrides> = formsById[FORMS_TEMPLATE_IDS.CONTACT];
  const contactFormValues = getContactFormInitialState({
    checkoutSettings,
    contact: addressAndContact.contact,
    country: addressAndContact.address?.country,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const contactFormWithOverrides = applyNestedForms(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    applyFormOverrides(
      applyRuleOverrides(contactFormSchema, contactFormValues),
      getContactFormOverrides({checkoutSettings, overridePhone: cashierMandatoryFields?.phone})
    )
  );

  const addressFormSchema = formsById[FORMS_TEMPLATE_IDS.ADDRESS];
  const addressFormValues = getAddressFormInitialState(checkoutSettings, addressAndContact.address);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const addressFormWithOverrides = applyNestedForms(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    applyFormOverrides(
      applyRuleOverrides(addressFormSchema, addressFormValues),
      getAddressFormOverrides({checkoutSettings, overrides: cashierMandatoryFields})
    )
  );

  const vatFormSchema = formsById[FORMS_TEMPLATE_IDS.VAT_ID];
  const vatFormValues = getVatFormInitialState(addressAndContact.contact);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const vatFormWithOverrides = applyNestedForms(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    applyFormOverrides(applyRuleOverrides(vatFormSchema, vatFormValues), {})
  );

  const validationErrors = await Promise.all([
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    validate(contactFormWithOverrides, contactFormValues),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    validate(addressFormWithOverrides, addressFormValues),
    ...(addressAndContact.address?.country && COUNTRIES_WITH_VAT.includes(addressAndContact.address?.country)
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        [validate(vatFormWithOverrides, vatFormValues)]
      : []),
  ]);
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
};
