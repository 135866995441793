import React from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManager} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {PaymentStep} from '../CheckoutSteps/Steps/PaymentStep/PaymentStep';
import {PlaceOrderStep} from '../CheckoutSteps/Steps/PlaceOrderStep/PlaceOrderStep';
import {StepId} from '../../../types/app.types';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {DeliveryMethodStep} from '../CheckoutSteps/Steps/DeliveryMethodStep/DeliveryMethodStep';

export enum FullFlowDataHook {
  root = 'FullFlow.root',
  title = 'FullFlow.title',
  expressCheckout = 'FullFlow.expressCheckout',
}

export const FullFlow = () => {
  const {
    checkoutStore: {ambassadorCheckout, updateCheckoutError, placeOrderError, checkout, isPickupFlow},
    stepsManagerStore: {shouldDisplayExpressCheckout, stepsList},
    memberStore: {isAddressesAppInstalled},
  } = useControllerProps();
  const {experiments} = useExperiments();

  return (
    <div data-hook={FullFlowDataHook.root}>
      {shouldDisplayExpressCheckout && (
        <div data-hook={FullFlowDataHook.expressCheckout}>
          <ExpressCheckoutButtons
            checkout={ambassadorCheckout}
            layout={'horizontal'}
            isShippable={checkout.hasShippableItems && !isPickupFlow}
            estimatedTotal={ambassadorCheckout.payNowTotalAfterGiftCard?.amount}
            useEstimationFlow={experiments.enabled(SPECS.UseEstimationFlowForExpressCheckout)}
          />
        </div>
      )}
      {isAddressesAppInstalled && <MemberInfoBox />}
      <StepsManager>
        {stepsList.map((step) => {
          /* istanbul ignore else */
          if (step.id === StepId.customerDetails) {
            return <CustomerDetailsStep key={StepId.customerDetails} />;
          } else if (step.id === StepId.payment) {
            return <PaymentStep key={StepId.payment} />;
          } else if (step.id === StepId.deliveryMethod) {
            return <DeliveryMethodStep key={StepId.deliveryMethod} />;
          } else if (step.id === StepId.placeOrder) {
            return <PlaceOrderStep key={StepId.placeOrder} />;
          } else {
            return null;
          }
        })}
      </StepsManager>
      {(checkout.errors.noItemsError || placeOrderError || updateCheckoutError) && <ErrorDialog />}
    </div>
  );
};
