import {TextButton} from 'wix-ui-tpa';
import {classes} from './MemberBillingAddressTitle.st.css';
import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {MemberDetailsState, StepId} from '../../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {MemberDetailsDataHook} from '../../../../MemberDetails/MemberDetails';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';

export const MemberBillingAddressTitle = () => {
  const localeKeys = useLocaleKeys();
  const {memberDetailsState, setMemberDetailsState} = useMemberDetailsData();
  const {
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const [shouldShowChangeButton, setShouldShowChangeButton] = useState(
    activeStep.stepId === StepId.payment && memberDetailsState === MemberDetailsState.COLLAPSED
  );

  useEffect(
    () => {
      if (
        shouldShowChangeButton !==
        (activeStep.stepId === StepId.payment && memberDetailsState === MemberDetailsState.COLLAPSED)
      ) {
        setShouldShowChangeButton(
          activeStep.stepId === StepId.payment && memberDetailsState === MemberDetailsState.COLLAPSED
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, memberDetailsState]
  );

  return (
    <div className={classes.titleContainer}>
      <BillingAddressTitle />
      {shouldShowChangeButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberDetailsDataHook.changeButton}
          className={classes.changeTitle}
          onClick={() => setMemberDetailsState(MemberDetailsState.OPEN)}>
          {localeKeys.checkout.change()}
        </TextButton>
      )}
    </div>
  );
};
