import React, {useEffect, useState} from 'react';
import {classes} from './MemberDetailsSummary.st.css';
import {ContactAndAddressSummary} from '../../ContactAndAddressSummary/ContactAndAddressSummary';
import {MemberDetailsState} from '../../../../types/app.types';
import {TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {AddressWithContactModel} from '../../../../domain/models/AddressWithContact.model';
import {useMemberDetailsData} from '../WithMemberDetailsData';

export enum MemberDetailsSummaryDataHook {
  editButton = 'MemberDetails.editButton',
}

export const MemberDetailsSummary = () => {
  const localeKeys = useLocaleKeys();

  const [memberDetails, setMemberDetails] = useState<AddressWithContactModel | undefined>(undefined);

  const {
    selectedAddressesServiceId,
    getMemberAddressBySelectedServiceId,
    memberDetailsState,
    areMemberDetailsValid,
    setMemberDetailsState,
  } = useMemberDetailsData();

  useEffect(
    () => {
      setMemberDetails(getMemberAddressBySelectedServiceId());
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [selectedAddressesServiceId]
  );

  const shouldShowEditButton = memberDetailsState === MemberDetailsState.OPEN && areMemberDetailsValid;

  return (
    <div className={classes.container}>
      <ContactAndAddressSummary contact={memberDetails?.contact} address={memberDetails?.address} />
      {shouldShowEditButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberDetailsSummaryDataHook.editButton}
          className={classes.editTitle}
          onClick={() => setMemberDetailsState(MemberDetailsState.EDIT)}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
