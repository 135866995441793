import React, {useEffect, useState} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {NextStepButton} from '../../StepsManager/Components/NextStepButton/NextStepButton';
import {ButtonPriority, ButtonSize, StatesButtonStates, Button} from 'wix-ui-tpa';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from './WithBillingData/WithBillingData';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {cashierWidgetEwalletPaymentMethod} from '../../../../../domain/utils/cashier.utils';
import {useFedopsLogger} from '@wix/yoshi-flow-editor';
import {FedopsInteractions} from '../../../constants';
import {MemberDetailsState, StepImplementationProps, StepState} from '../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle/BillingAddressTitle';
import {classes} from './PaymentStep.st.css';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {useMemberDetailsData, withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {CheckoutModel} from '../../../../../domain/models/Checkout.model';
import {isBillingInfoValid} from '../../../Form/FormValidations';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
  continueButton = 'PaymentStep.continue',
  cancelButton = 'PaymentStep.cancel',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const InternalPaymentStep = ({index}: DynamicStepComponentProps) => {
  const localeKeys = useLocaleKeys();
  const {paymentsApi, activePaymentId} = usePaymentsApi();

  const {
    checkoutStore: {checkout, setPaymentAndBillingDetails, updateCheckoutError, isShippingFlow},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
    memberStore: {isMember},
  } = useControllerProps();

  const fedops = useFedopsLogger();
  const stepState = stepsList[index].state;

  const isEwalletPaymentMethod = activePaymentId ? cashierWidgetEwalletPaymentMethod.includes(activePaymentId) : false;
  const shouldShowBillingForm = !(isEwalletPaymentMethod ? checkout.hasShippableItems : false);

  const {isFormValid, initForm, getBillingFormDataForSubmit, billingSameAsShipping} = useBillingData();

  const {
    selectedAddressesServiceId,
    resetMemberDetailsState,
    setMemberDetailsState,
    memberDetailsState,
    areMemberDetailsValid,
  } = useMemberDetailsData();

  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);

  const [buttonDisabledState, setButtonDisabledState] = useState(!areFormsLoaded);

  useEffect(() => {
    if (updateCheckoutError) {
      setButtonState(StatesButtonStates.IDLE);
      setButtonDisabledState(false);
    }
  }, [updateCheckoutError]);

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        setButtonState(StatesButtonStates.IDLE);
        setButtonDisabledState(!areFormsLoaded);
        initForm();
        if (isMember) {
          resetMemberDetailsState();
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  useEffect(() => {
    setButtonDisabledState(!(areFormsLoaded && areMemberDetailsValid));
  }, [areFormsLoaded, areMemberDetailsValid]);

  const validateAndSubmit = async () => {
    setButtonDisabledState(true);
    setButtonState(StatesButtonStates.IN_PROGRESS);
    const isBillingFormValid = shouldShowBillingForm ? await isFormValid() : true;
    const setBillingSameAsShipping = !shouldShowBillingForm || (isShippingFlow && billingSameAsShipping);
    fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
    const {isValid: isPaymentValid} = (await paymentsApi?.validate()) ?? /* istanbul ignore next */ {};
    fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);

    if (!isPaymentValid || !isBillingFormValid) {
      setButtonDisabledState(false);
      setButtonState(StatesButtonStates.IDLE);
      return;
    }

    void setPaymentAndBillingDetails({
      ...getBillingFormDataForSubmit(),
      addressesServiceId: selectedAddressesServiceId,
      setBillingSameAsShipping,
      activePaymentId,
    });
  };

  return (
    <>
      <StepHeader index={index} dataHook={PaymentStepDataHook.header} label={localeKeys.checkout.payment()} />
      <PaymentWidgetWrapper stepState={stepState} />
      {stepState === StepState.COLLAPSED && shouldShowBillingForm && (
        <>
          <BillingAddressTitle />
          <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
        </>
      )}
      {stepState === StepState.OPEN && (
        <>
          {shouldShowBillingForm && <BillingDetails />}
          <div className={classes.buttonsWrapper}>
            {memberDetailsState === MemberDetailsState.EDIT && (
              <Button
                data-hook={PaymentStepDataHook.cancelButton}
                priority={ButtonPriority.basicSecondary}
                size={ButtonSize.medium}
                className={classes.cancelButton}
                onClick={() => setMemberDetailsState(MemberDetailsState.OPEN)}
                upgrade>
                {localeKeys.checkout.cancel.cta()}
              </Button>
            )}
            <NextStepButton
              onClick={() => void validateAndSubmit()}
              text={
                memberDetailsState !== MemberDetailsState.EDIT
                  ? localeKeys.checkout.continue_button_label()
                  : localeKeys.checkout.saveAndContinue.cta()
              }
              dataHook={PaymentStepDataHook.continueButton}
              disabled={buttonDisabledState}
              buttonState={buttonState}
            />
          </div>
        </>
      )}
    </>
  );
};

const WrappedInternalPaymentStep = withMemberDetailsData(
  withBillingData(InternalPaymentStep),
  (checkout: CheckoutModel) => checkout.billingInfo,
  isBillingInfoValid,
  (controllerProps) => controllerProps.paymentStore.cashierMandatoryFields
);

export const PaymentStep = ({index}: StepImplementationProps) => {
  return <DynamicStep index={index!} dataHook={PaymentStepDataHook.root} component={WrappedInternalPaymentStep} />;
};
