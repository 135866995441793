import {MemberBillingAddressTitle} from '../BillingAddressTitle/MemberBillingAddressTitle';
import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';
import {BillingSameAsShippingCheckbox} from '../BillingSameAsShippingCheckbox';
import {BillingDetailsForm} from '../BillingDetailsForm/BillingDetailsForm';
import React from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './BillingDetails.st.css';
import {MemberDetails} from '../../../../MemberDetails/MemberDetails';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
}

export const BillingDetails = () => {
  const {
    checkoutStore: {isShippingFlow},
    memberStore: {isMember, isAddressesAppInstalled},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      {isMember && isAddressesAppInstalled ? <MemberBillingAddressTitle /> : <BillingAddressTitle />}
      {isShippingFlow && <BillingSameAsShippingCheckbox />}
      {isMember && isAddressesAppInstalled ? (
        <MemberDetails
          dropdownLabel={localeKeys.chooseAddressModal.billing_address_title()}
          Form={() => <BillingDetailsForm />}
        />
      ) : (
        <BillingDetailsForm />
      )}
    </div>
  );
};
