import {ControllerProps, MemberDetailsState} from '../../../types/app.types';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import React, {ComponentType, createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {CheckoutModel} from '../../../domain/models/Checkout.model';
import {applyOverrides as applyRuleOverrides} from '@wix/form-conditions/dist/types/lib/apply-overrides';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';

export type MemberDetailsDataContextType = {
  selectedAddressesServiceId?: string;
  setSelectedAddressesServiceId: (id: string) => void;
  memberDetailsState: MemberDetailsState;
  setMemberDetailsState: (state: MemberDetailsState) => void;
  resetMemberDetailsState: () => void;
  getMemberAddressBySelectedServiceId: () => AddressWithContactModel | undefined;
  areMemberDetailsValid: boolean;
};

export type MemberDetailsValidator<S> = (options: {
  formsById: {[key: string]: ReturnType<typeof applyRuleOverrides>};
  addressAndContact: AddressWithContactModel;
  checkoutSettings: CheckoutSettingsModel;
  context: S;
}) => Promise<boolean>;

export const MemberDetailsDataContext = createContext({} as MemberDetailsDataContextType);

/* eslint-disable sonarjs/cognitive-complexity */
export function withMemberDetailsData<T extends object, S>(
  Component: ComponentType<T>,
  getAddressAndContactFromCheckout: (checkout: CheckoutModel) => AddressWithContactModel | undefined,
  validator: MemberDetailsValidator<S>,
  getValidationContext: (controllerProps: ControllerProps) => S
) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {addressesInfo, isAddressesAppInstalled, isMember, defaultAddressId},
    } = useControllerProps();
    const {wfConfig} = useControllerProps() as unknown as {wfConfig?: {formsById: any}};

    const [memberDetailsState, setMemberDetailsState] = useState(MemberDetailsState.COLLAPSED);
    const [areMemberDetailsValid, setAreMemberDetailsValid] = useState(true);

    const getInitialSelectedAddressesServiceId = (): string | undefined => {
      if (!isMember || !isAddressesAppInstalled) {
        return undefined;
      }

      const addressAndContact = getAddressAndContactFromCheckout(checkout);

      if (addressAndContact?.addressesServiceId) {
        return addressAndContact?.addressesServiceId;
      }

      setMemberDetailsState(MemberDetailsState.OPEN);

      if (!isAddressFullEnough(addressAndContact) && addressesInfo.addresses?.length) {
        return defaultAddressId;
      }

      return ADD_NEW_ADDRESS_ID;
    };

    const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);

    const getMemberAddressBySelectedServiceId = useCallback(() => {
      return addressesInfo.addresses?.find((address) => address.addressesServiceId === selectedAddressesServiceId);
    }, [selectedAddressesServiceId, addressesInfo]);

    const validationContext = getValidationContext(useControllerProps());

    useEffect(
      () => {
        if (!isMember || !isAddressesAppInstalled) {
          return;
        }
        const validateData = async (addressAndContact: AddressWithContactModel) => {
          const isValid = await validator({
            formsById: wfConfig?.formsById,
            addressAndContact,
            checkoutSettings,
            context: validationContext,
          });

          setAreMemberDetailsValid(isValid);
        };

        const memberAddress = getMemberAddressBySelectedServiceId();
        wfConfig?.formsById && memberAddress && void validateData(memberAddress);
      },
      /* eslint-disable react-hooks/exhaustive-deps*/ [
        validationContext,
        checkoutSettings,
        wfConfig?.formsById,
        selectedAddressesServiceId,
      ]
    );

    useEffect(() => {
      if (memberDetailsState === MemberDetailsState.COLLAPSED && !areMemberDetailsValid) {
        setMemberDetailsState(MemberDetailsState.OPEN);
      }
    }, [areMemberDetailsValid, setMemberDetailsState, memberDetailsState]);

    const resetMemberDetailsState = () => {
      setMemberDetailsState(areMemberDetailsValid ? MemberDetailsState.COLLAPSED : MemberDetailsState.OPEN);
      setSelectedAddressesServiceId(getInitialSelectedAddressesServiceId());
    };

    return (
      <MemberDetailsDataContext.Provider
        value={{
          selectedAddressesServiceId,
          setSelectedAddressesServiceId,
          getMemberAddressBySelectedServiceId,
          memberDetailsState,
          setMemberDetailsState,
          resetMemberDetailsState,
          areMemberDetailsValid,
        }}>
        <Component {...props} />
      </MemberDetailsDataContext.Provider>
    );
  };
}

export function useMemberDetailsData() {
  const {
    selectedAddressesServiceId,
    setSelectedAddressesServiceId,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
    getMemberAddressBySelectedServiceId,
    areMemberDetailsValid,
  } = useContext(MemberDetailsDataContext);

  return {
    selectedAddressesServiceId,
    setSelectedAddressesServiceId,
    getMemberAddressBySelectedServiceId,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
    areMemberDetailsValid,
  };
}

function isAddressFullEnough(addressAndContact?: AddressWithContactModel) {
  return Boolean(
    addressAndContact?.address?.addressLine ||
      (addressAndContact?.address?.city && addressAndContact?.address?.postalCode) ||
      addressAndContact?.address?.streetAddress?.name
  );
}
